<template>
    <div class="container">
        <Spinner v-if="loading" />
        <table class="table table-hover" v-else>
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Language</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(language, key) in languages" :key="key">
                    <th scope="row">{{ key + 1 }}</th>
                    <td>
                        <router-link :to="{ name: 'LanguageValues', params: { key: language.key } }">{{ language.name }}
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import axios from 'axios';
import Spinner from '../../components/ui/Spinner.vue';

export default {
    components: { Spinner },
    data() {
        return {
            loading: false,
            languages: []
        }
    },
    created() {
        console.log("created");
    },
    mounted() {
        console.log("mounted");
        this.getLanguages();
    },
    methods: {
        async getLanguages() {
            this.loading = true;
            const { data } = await axios.get('admin/language/list');
            this.languages = data.languages;
            this.loading = false;
        }
    }
};
</script>
  